import { useSDK } from '../../hooks/useData.js'
import PickerWizard, { WizardStep } from '../PickerWizard.js'
import React, { useRef, useState } from 'react'
import { Box, Button, Icon, Text } from '@chakra-ui/react'
import { PickerWrapper } from './PickerWrapper.js'
import { Data, DataSettings, DataPath, DataScope } from '@sitecore-feaas/clientside/headless'
import { PickerProps } from './Picker.js'
import { DatasourceModel, ExternalComponentModel, getEmbedDefinition } from '@sitecore-feaas/sdk'
import PickerDatasourcesSource from './PickerDatasourcesSource.js'
import { mdiCloudRefreshOutline } from '@mdi/js'
import PickerDatasourcesFetchUpdates from './PickerDatasourcesFetchUpdates.js'
import PickerDatasourcesItems from './PickerDatasourcesItems.js'
import { getComponentConfigurablePropertyNames, objectKeysToCamelCase, toCamelCase } from '@sitecore/byoc'

const PickerDatasources = ({
  context,
  embed,
  datasource,
  settings = {},
  onModeChange,
  onChange,
  variant
}: {
  context: any
  embed: PickerProps['embed']
  datasource: DatasourceModel
  settings: DataSettings | Data
  onModeChange: PickerProps['onModeChange']
  onChange: (settings: DataSettings | Data) => void
  variant?: PickerProps['variant']
}) => {
  const datasources = useSDK('datasources')

  const externalComponent = ExternalComponentModel.isExternalComponent(embed) ? embed : null
  const configurableProperties = externalComponent && getComponentConfigurablePropertyNames(externalComponent)
  const byocProps = configurableProperties?.map((i) => toCamelCase(i))
  const embedDefinition = getEmbedDefinition(context)
  const isBYOC = embedDefinition?.type === 'byoc'

  const steps: WizardStep[] = isBYOC ? [{ title: 'Source' }, { title: 'Items' }] : [{ title: 'Items' }]

  const jsonpath = settings && 'jsonpath' in settings ? settings.jsonpath : '$'
  const qualifier = DataPath.getQualifier(jsonpath)
  const [repeating, setRepeating] = useState<'no-limit' | 'limit' | 'range'>(() => {
    return DataPath.parseLimit(qualifier) ? 'limit' : DataPath.parseRange(qualifier) ? 'range' : 'no-limit'
  })

  const [fetchUpdatesVisible, setFetchUpdatesVisible] = useState(false)

  return (
    <>
      <PickerWrapper
        title={fetchUpdatesVisible ? 'Fetch updates' : 'Datasources'}
        onBack={() => {
          if (fetchUpdatesVisible) setFetchUpdatesVisible(false)
          else onModeChange(null)
        }}
        variant={variant}
      >
        <Box p={3}>
          {fetchUpdatesVisible ? (
            <PickerDatasourcesFetchUpdates
              datasource={datasource}
              onChange={onChange}
              onDiscard={() => {
                setFetchUpdatesVisible(false)
              }}
            />
          ) : (
            <PickerWizard
              defaultStep={isBYOC && datasource ? 1 : 0}
              steps={steps}
              extraButton={({ activeStep }: { activeStep: number }) =>
                steps[activeStep].title === 'Items' && datasource?.type !== 'xmTemplate' && variant == 'pages' ? (
                  <Box>
                    <Button size={'xs'} onClick={() => setFetchUpdatesVisible(true)}>
                      <Icon mr={2}>
                        <path d={mdiCloudRefreshOutline} />
                      </Icon>
                      <Text fontSize={12}>Customize</Text>
                    </Button>
                  </Box>
                ) : null
              }
              onComplete={
                isBYOC
                  ? () => {
                      onChange(
                        !(settings as DataSettings)?.url
                          ? datasource.sample
                          : DataSettings({ ...settings, ...datasource.settings, jsonpath })
                      )
                      onModeChange(null)
                    }
                  : null
              }
            >
              {isBYOC && (
                <PickerDatasourcesSource
                  datasources={datasources}
                  datasource={datasource}
                  onDatasourceChange={(datasource) => {
                    onChange(null)
                    onModeChange('datasources', datasource.id)
                  }}
                  byocProps={byocProps}
                />
              )}
              <PickerDatasourcesItems
                datasource={datasource}
                jsonpath={jsonpath}
                qualifier={qualifier}
                repeating={repeating}
                variant={variant}
                isBYOC={isBYOC}
                onConfigure={(jsonpath) => {
                  onChange(DataSettings({ ...settings, jsonpath }))
                }}
                onRepeatingChange={setRepeating}
              />
            </PickerWizard>
          )}
        </Box>
      </PickerWrapper>
    </>
  )
}

export default PickerDatasources
