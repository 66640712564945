import {
  Alert,
  AlertDescription,
  AlertIcon,
  Badge,
  Box,
  Flex,
  HStack,
  Icon,
  IconButton,
  Tooltip,
  VStack,
  Text,
  Divider,
  Button
} from '@chakra-ui/react'
import { mdiAlertOutline, mdiCircleSmall, mdiRestore, mdiInformationOutline } from '@mdi/js'
import { Style } from '@sitecore-feaas/sdk'
import { pluralize } from 'inflection'
import { Fragment, useState } from 'react'

import { DialogMenuItem } from '../editor/dialog/DialogMenu.js'
import { getIconByStyleType } from '../styles/sidebar.js'
import { checkerboard } from '../styles/previews/index.js'
import type { PickerProps } from './Picker.js'
import { PickerWrapper } from './PickerWrapper.js'
import PickerStylePreviewSelect from './PickerStylePreviewSelect.js'
import PickerElementAspect from './PickerElementAspect.js'
import { useLibrary } from '../../exports.js'
import PickerElementFloatingFooter from './PickerElementFloatingFooter.js'

const BADGE_STYLES = {
  info: { colorScheme: 'teal', icon: mdiInformationOutline, iconClassName: 'info-icon' },
  warning: { colorScheme: 'orange', icon: mdiAlertOutline, iconClassName: 'alert-icon' },
  default: { colorScheme: 'blackAlpha', icon: '', iconClassName: '' }
}

const CUSTOMIZATION_BADGE = {
  default: { ...BADGE_STYLES.default, label: '' },
  allowed: { ...BADGE_STYLES.info, label: 'This style is not the default' },
  nonAllowed: {
    ...BADGE_STYLES.warning,
    label: 'This style has not been assigned to this element in the Style library '
  },
  oneOff: { ...BADGE_STYLES.warning, label: 'This style is custom ' }
}

const PickerElement = <
  RuleElement extends Style.Rule.Element = Style.Rule.Element,
  ElementType extends Style.Type.Element = Style.typeOf<RuleElement>
>({
  classList,
  variant,
  themeClassList,
  theme,
  rules,
  customRules = [],
  context,
  onChange,
  onComboChange,
  onModeChange
}: {
  variant?: PickerProps['variant']
  classList: string[]
  themeClassList: string[]
  theme: Style.Rule<'theme'>
  rules: Style.Rule[]
  customRules?: Style.Rule[]
  context: Style.Context
  onChange: (style: Style.Rule | Style.Empty) => void
  onComboChange: (combo: Style.Theme.Combo) => void
  onModeChange: PickerProps['onModeChange']
}) => {
  const library = useLibrary()

  const [handleBackButton, setHandleBackButton] = useState<() => void>()
  const [aspectType, setAspectType] = useState<Style.Type.ElementAspect<ElementType>>()

  const handleAspectSelect = (aspect: Style.Type.ElementAspect) => {
    setAspectType(aspect)
    setHandleBackButton((prevState) => () => {
      setAspectType(null)
      setHandleBackButton(() => prevState)
    })
  }

  const allElements = Style.Set.getContextElementChoices(rules, classList)
  const definition = Style.Set.getContextDefinition(rules, classList)
  const stylesLink = `${library.getPath()}/styles/${definition.id}/${definition.type}`

  // Show Alert if element has no styles
  if (!allElements.length) {
    return (
      <PickerWrapper
        onBack={handleBackButton || (() => onModeChange())}
        title={`${definition?.label || 'Choice of '} ${aspectType || 'styles'}`}
      >
        <Flex flexDirection='column' pt={5} px={4} h='full'>
          <Alert status='warning' {...{ alignItems: 'flex-start', marginBottom: '12px' }}>
            <AlertIcon />
            <AlertDescription>
              The selected element has no styles. Please select an element that can be styled
            </AlertDescription>
          </Alert>
          <PickerElementFloatingFooter ml={-4} mr={-4}>
            <Button
              as='a'
              variant='solid'
              colorScheme='primary'
              href={stylesLink}
              target='_blank'
              rel='noopener noreferrer'
              onClick={() => window.open(stylesLink, '_target')}
            >
              <Box>
                {'New '}
                <Box as='span' textTransform='lowercase'>
                  {definition?.label}
                </Box>
              </Box>
              <Icon ml={2} boxSize='icon-lg'>
                <path d={mdiInformationOutline} />
              </Icon>
            </Button>
          </PickerElementFloatingFooter>
        </Flex>
      </PickerWrapper>
    )
  }

  const element = Style.Set.getContextElement(rules, classList)
  const combo = Style.Set.getContextCombo(rules, classList, themeClassList)

  const elementStyle = element || (Style.Set.findById(rules, combo?.refId) as Style.Rule.Element)
  const effectiveStyles = Style.Set.join(rules, customRules)
  const assignedStyles = Style.Set.getContextAssigned(effectiveStyles, classList)

  const usedCombo = !element && combo
  const defaultCombo =
    Style.Set.getContextThemeCombo(rules, classList, themeClassList) ||
    Style.Set.getContextThemeDefaultCombo(rules, classList, themeClassList)

  const assignedAspects = assignedStyles.filter((style) =>
    Style.TypeDefinitions[definition.type].aspects.some((aspect) => style.type == aspect)
  ) as Style.Rule.ElementAspect<ElementType>[]

  const aspectOptions = elementStyle ? Style.Set.getElementAspectChoicesByType(rules, elementStyle) : null
  const customizationType = elementStyle
    ? Style.Set.getElementCustomizationType(effectiveStyles, elementStyle, assignedAspects)
    : Style.Set.getDefinitionCustomizationType(effectiveStyles, definition, assignedAspects)
  const isDefaultCombo = usedCombo === defaultCombo
  const hasChanges =
    assignedAspects.some((aspect) => aspect.details.id !== usedCombo[`${aspect.type}Id`]) ||
    customizationType === 'oneOff'
  return (
    <PickerWrapper
      onBack={handleBackButton || (() => onModeChange())}
      title={`${definition?.label || 'Choice of '} ${aspectType || 'styles'}`}
    >
      <Flex flexDirection='column' className='picker-element-customization' align='stretch' h='full' pt={6} px={3}>
        {!aspectType ? (
          <>
            <HStack mb={3} justifyContent='space-between' minH='icon-xl'>
              <Text fontWeight='bold' textTransform='capitalize'>
                {definition ? pluralize(definition.label) : 'Elements'}
              </Text>
              {(element || (usedCombo && !isDefaultCombo)) && (
                <Tooltip hasArrow={false} label='Reset to default style' placement='top-end'>
                  <IconButton
                    aria-label='Reset to default style'
                    icon={
                      <Icon display='block' mx='auto' color='primary.600' boxSize='icon-xl'>
                        <path d={mdiRestore} />
                      </Icon>
                    }
                    size='xs'
                    onClick={() => {
                      if (element) onChange({ type: element.type })
                      else onComboChange(defaultCombo)
                      setAspectType(null)
                      setHandleBackButton(null)
                    }}
                  ></IconButton>
                </Tooltip>
              )}
            </HStack>
            <PickerStylePreviewSelect
              element={element}
              allElements={allElements}
              elementStyle={elementStyle}
              rules={rules}
              classList={classList}
              themeClassList={themeClassList}
              theme={theme}
              usedCombo={usedCombo}
              defaultCombo={defaultCombo}
              onChange={onChange}
              onComboChange={onComboChange}
              customRules={customRules}
              context={context}
            />

            {elementStyle && (
              <>
                <HStack justifyContent='space-between' minH='icon-xl' mb={2} mt={6}>
                  <Text fontWeight='bold'>{definition?.label} styles</Text>
                  {hasChanges && (
                    <Tooltip hasArrow={false} label='Reset rules to their default values' placement='top-end'>
                      <IconButton
                        aria-label='Reset rules to their default values'
                        icon={
                          <Icon display='block' mx='auto' color='primary.600' boxSize='icon-xl'>
                            <path d={mdiRestore} />
                          </Icon>
                        }
                        size='xs'
                        onClick={() => {
                          if (usedCombo) onComboChange(usedCombo)
                          else onChange(elementStyle)
                        }}
                      ></IconButton>
                    </Tooltip>
                  )}
                </HStack>
                <VStack align='stretch' divider={<Divider />}>
                  {Style.TypeDefinitions[definition.type].aspects.map(
                    <A extends Style.Type.ElementAspect<ElementType>>(aspect: A, index: number) => {
                      /** NOTE: aspectOptions[aspect.type] list includes oneOff styles because it comes from effective styles */
                      const defaultStyle = isDefaultCombo
                        ? Style.Set.getContextComboAspect(rules, context, aspect, themeClassList)
                        : Style.Set.getAspectDefault(aspectOptions[aspect])
                      const currentStyle = assignedAspects.find((style) => style.type === aspect) || defaultStyle
                      const type = Style.Set.getAspectCustomizationType(
                        rules,
                        currentStyle,
                        // Bring default value to start of array
                        aspectOptions[aspect].sort(
                          (a, b) =>
                            Number(b.details.id === defaultStyle.details.id) -
                            Number(a.details.id === defaultStyle.details.id)
                        )
                      )
                      const forms = Style.getForms(aspect)
                      const summary =
                        type === 'oneOff'
                          ? Style.Props.summarize(currentStyle.type, currentStyle.props, rules)
                          : currentStyle.details.title
                      const { colorScheme, icon, iconClassName, label } = CUSTOMIZATION_BADGE[type]
                      return (
                        <Fragment key={index}>
                          <DialogMenuItem
                            key={index}
                            id={aspect}
                            className='picker-element-aspect'
                            isDisabled={false}
                            onNavigate={handleAspectSelect}
                            icon={getIconByStyleType(aspect)}
                            label={Style.TypeDefinitions[aspect].label}
                            ariaLabel='Customize'
                            tooltip='Customize'
                            badge={() => (
                              <Badge colorScheme={colorScheme}>
                                <Tooltip hasArrow={false} placement='top-end' label={label}>
                                  <HStack>
                                    {icon && (
                                      <Icon boxSize='icon-sm' className={iconClassName}>
                                        <path d={icon} />
                                      </Icon>
                                    )}
                                    <Text textOverflow='ellipsis' overflow='hidden' maxWidth='100px'>
                                      {summary}
                                    </Text>
                                  </HStack>
                                </Tooltip>
                              </Badge>
                            )}
                          />
                          {forms.map((form) => {
                            const summary = Style.Props.summarize(currentStyle.type, currentStyle.props, rules, form.id)
                            return (
                              <Box key={form.id}>
                                <Flex py={2} justifyContent='space-between'>
                                  <HStack>
                                    <Icon boxSize='icon-xl'>
                                      <path color='black' opacity={0.5} d={mdiCircleSmall} />
                                    </Icon>
                                    <Box color='blackAlpha.500'>{form.label}:</Box>
                                    {String(summary).match(/^rgba(.*?)$/) ? (
                                      <Box
                                        color={{ summary }}
                                        boxSize='18px'
                                        borderRadius='2px'
                                        border='1px solid #ccc'
                                        css={checkerboard}
                                      ></Box>
                                    ) : (
                                      <Text color='blackAlpha.500'>{summary}</Text>
                                    )}
                                  </HStack>
                                </Flex>
                              </Box>
                            )
                          })}
                        </Fragment>
                      )
                    }
                  )}
                </VStack>
              </>
            )}
            <PickerElementFloatingFooter ml={-3} mr={-3}>
              <Button
                as='a'
                variant='solid'
                colorScheme='primary'
                href={stylesLink}
                target='_blank'
                rel='noopener noreferrer'
                onClick={() => window.open(stylesLink, '_target')}
              >
                New {definition?.label.toLowerCase()}
                <Icon ml={2} boxSize='icon-lg'>
                  <path d={mdiInformationOutline} />
                </Icon>
              </Button>
            </PickerElementFloatingFooter>
          </>
        ) : (
          <PickerElementAspect
            aspect={aspectType}
            assignedAspects={assignedAspects}
            aspectOptions={aspectOptions}
            rules={rules}
            onChange={onChange}
            setHandleBackButton={setHandleBackButton}
            isDefaultCombo={usedCombo && usedCombo === defaultCombo}
            themeClassList={themeClassList}
            context={context}
          />
        )}
      </Flex>
    </PickerWrapper>
  )
}

export default PickerElement
